<template>
  <!-- <WithPage v-slot="{ isPageLoading, thePage }" slug="contact"> -->
  <div>
    <BaseThemeComponent
      v-if="thePage.contents.header_title || thePage.contents.header_img"
      name="PageHeader"
      :bg-image="thePage.contents.header_img"
      :title="thePage.contents.header_title"
    />

    <BaseThemeComponent name="ContactMapSection" />

    <BaseThemeComponent name="ContactSection" />

    <BaseThemeComponent
      v-if="
        thePage.contents.ns_1_title ||
          thePage.contents.ns_1_description ||
          thePage.contents.ns_1_img
      "
      name="PageSectionNarrative"
      section-id="1"
      class="bubs-charcoal--text pt-5"
      :bg-color="thePage.contents.ns_1_bg_color"
      :text-cols="7"
      full-bleed-bg
    >
      <template #title>
        <VLayout row>
          <VFlex xs12 md11 offset-md1>
            <BasePageContent
              :content-key="`ns_1_title`"
              :tag-class="[
                thePage.contents.ns_1_title_class,
                'section--inner-title pb-3 mb-3 d-inline-block',
              ]"
              tag="h3"/></VFlex
        ></VLayout>
      </template>
      <VLayout row>
        <VFlex xs12 md11 offset-md1>
          <BasePageContent
            content-key="ns_1_description"
            tag="p"
            class="r-title"
          />
        </VFlex>
      </VLayout>
    </BaseThemeComponent>

    <BasePageSectionImageBreak
      v-if="thePage.contents.img_break_1"
      :bg-image="thePage.contents.img_break_1"
    />

    <BaseThemeComponent
      v-if="
        thePage.contents.ns_2_title ||
          thePage.contents.ns_2_description ||
          thePage.contents.ns_2_img
      "
      name="PageSectionNarrative"
      section-id="2"
      class="bubs-charcoal--text pt-5"
      :bg-color="thePage.contents.ns_2_bg_color"
      :title-class="thePage.contents.ns_2_title_class"
      :text-cols="6"
      full-bleed-bg
    >
      <VLayout row>
        <VFlex xs12 md11>
          <BasePageContent
            content-key="ns_2_description"
            tag="p"
            class="r-title"
          />
        </VFlex>
      </VLayout>
    </BaseThemeComponent>

    <BaseThemeComponent
      v-if="thePage.contents.team_members"
      name="TeamBios"
      :team-members="JSON.parse(thePage.contents.team_members)"
    />
    <BaseThemeComponent
      v-if="thePage.contents.team_members_aux"
      name="TeamBiosAux"
      :bg-color="thePage.contents.team_members_aux_bg_color"
      :team-members="JSON.parse(thePage.contents.team_members_aux)"
    />

    <BasePageSectionImageBreak
      v-if="thePage.contents.img_break_2"
      class="align-center"
      :bg-image="thePage.contents.img_break_2"
      :title="thePage.contents.img_break_2_title"
      title-class="header-4 white--text text-xs-center"
    />

    <BaseThemeComponent
      v-if="
        thePage.contents.ns_3_title ||
          thePage.contents.ns_3_description ||
          thePage.contents.ns_3_img
      "
      name="PageSectionNarrative"
      section-id="3"
      class="bubs-charcoal--text"
      :text-cols="7"
      fluid
    >
      <VLayout row justify-center>
        <VFlex xs12 sm12 md10>
          <BasePageContent
            content-key="ns_3_description"
            tag="p"
            class="r-headline"
          />
        </VFlex>
      </VLayout>
    </BaseThemeComponent>

    <BaseThemeComponent
      v-if="
        thePage.contents.ns_4_title ||
          thePage.contents.ns_4_description ||
          thePage.contents.ns_4_img
      "
      name="PageSectionNarrative"
      section-id="4"
      class="white--text"
      :text-cols="7"
      fluid
    >
      <VLayout row>
        <VFlex xs12 md10>
          <BasePageContent
            content-key="ns_4_description"
            tag="p"
            class="r-headline"
          />
          <BaseButtonOutline
            v-if="thePage.contents.ns_4_btn_text"
            :outline-color="thePage.contents.ns_4_btn_outline_color"
            class="ml-0"
            :to="thePage.contents.ns_4_btn_route"
            :href="thePage.contents.ns_4_btn_link"
          >
            {{ thePage.contents.ns_4_btn_text }}
          </BaseButtonOutline>
        </VFlex>
      </VLayout>
    </BaseThemeComponent>
  </div>
  <!-- </WithPage> -->
</template>

<script>
import formatPhone from '@utils/format-phone'
import PageMixin from '@mixins/page'

export default {
  name: 'BubsContact',
  components: {},
  mixins: [PageMixin],
  data() {
    return {}
  },
  methods: { formatPhone },
}
</script>
