var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.thePage.contents.header_title || _vm.thePage.contents.header_img)?_c('BaseThemeComponent',{attrs:{"name":"PageHeader","bg-image":_vm.thePage.contents.header_img,"title":_vm.thePage.contents.header_title}}):_vm._e(),_c('BaseThemeComponent',{attrs:{"name":"ContactMapSection"}}),_c('BaseThemeComponent',{attrs:{"name":"ContactSection"}}),(
      _vm.thePage.contents.ns_1_title ||
        _vm.thePage.contents.ns_1_description ||
        _vm.thePage.contents.ns_1_img
    )?_c('BaseThemeComponent',{staticClass:"bubs-charcoal--text pt-5",attrs:{"name":"PageSectionNarrative","section-id":"1","bg-color":_vm.thePage.contents.ns_1_bg_color,"text-cols":7,"full-bleed-bg":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('VLayout',{attrs:{"row":""}},[_c('VFlex',{attrs:{"xs12":"","md11":"","offset-md1":""}},[_c('BasePageContent',{attrs:{"content-key":"ns_1_title","tag-class":[
              _vm.thePage.contents.ns_1_title_class,
              'section--inner-title pb-3 mb-3 d-inline-block' ],"tag":"h3"}})],1)],1)]},proxy:true}],null,false,532987984)},[_c('VLayout',{attrs:{"row":""}},[_c('VFlex',{attrs:{"xs12":"","md11":"","offset-md1":""}},[_c('BasePageContent',{staticClass:"r-title",attrs:{"content-key":"ns_1_description","tag":"p"}})],1)],1)],1):_vm._e(),(_vm.thePage.contents.img_break_1)?_c('BasePageSectionImageBreak',{attrs:{"bg-image":_vm.thePage.contents.img_break_1}}):_vm._e(),(
      _vm.thePage.contents.ns_2_title ||
        _vm.thePage.contents.ns_2_description ||
        _vm.thePage.contents.ns_2_img
    )?_c('BaseThemeComponent',{staticClass:"bubs-charcoal--text pt-5",attrs:{"name":"PageSectionNarrative","section-id":"2","bg-color":_vm.thePage.contents.ns_2_bg_color,"title-class":_vm.thePage.contents.ns_2_title_class,"text-cols":6,"full-bleed-bg":""}},[_c('VLayout',{attrs:{"row":""}},[_c('VFlex',{attrs:{"xs12":"","md11":""}},[_c('BasePageContent',{staticClass:"r-title",attrs:{"content-key":"ns_2_description","tag":"p"}})],1)],1)],1):_vm._e(),(_vm.thePage.contents.team_members)?_c('BaseThemeComponent',{attrs:{"name":"TeamBios","team-members":JSON.parse(_vm.thePage.contents.team_members)}}):_vm._e(),(_vm.thePage.contents.team_members_aux)?_c('BaseThemeComponent',{attrs:{"name":"TeamBiosAux","bg-color":_vm.thePage.contents.team_members_aux_bg_color,"team-members":JSON.parse(_vm.thePage.contents.team_members_aux)}}):_vm._e(),(_vm.thePage.contents.img_break_2)?_c('BasePageSectionImageBreak',{staticClass:"align-center",attrs:{"bg-image":_vm.thePage.contents.img_break_2,"title":_vm.thePage.contents.img_break_2_title,"title-class":"header-4 white--text text-xs-center"}}):_vm._e(),(
      _vm.thePage.contents.ns_3_title ||
        _vm.thePage.contents.ns_3_description ||
        _vm.thePage.contents.ns_3_img
    )?_c('BaseThemeComponent',{staticClass:"bubs-charcoal--text",attrs:{"name":"PageSectionNarrative","section-id":"3","text-cols":7,"fluid":""}},[_c('VLayout',{attrs:{"row":"","justify-center":""}},[_c('VFlex',{attrs:{"xs12":"","sm12":"","md10":""}},[_c('BasePageContent',{staticClass:"r-headline",attrs:{"content-key":"ns_3_description","tag":"p"}})],1)],1)],1):_vm._e(),(
      _vm.thePage.contents.ns_4_title ||
        _vm.thePage.contents.ns_4_description ||
        _vm.thePage.contents.ns_4_img
    )?_c('BaseThemeComponent',{staticClass:"white--text",attrs:{"name":"PageSectionNarrative","section-id":"4","text-cols":7,"fluid":""}},[_c('VLayout',{attrs:{"row":""}},[_c('VFlex',{attrs:{"xs12":"","md10":""}},[_c('BasePageContent',{staticClass:"r-headline",attrs:{"content-key":"ns_4_description","tag":"p"}}),(_vm.thePage.contents.ns_4_btn_text)?_c('BaseButtonOutline',{staticClass:"ml-0",attrs:{"outline-color":_vm.thePage.contents.ns_4_btn_outline_color,"to":_vm.thePage.contents.ns_4_btn_route,"href":_vm.thePage.contents.ns_4_btn_link}},[_vm._v(" "+_vm._s(_vm.thePage.contents.ns_4_btn_text)+" ")]):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }